<template>
	<div id="PreLoginPage" >

		<div id="AzureTemplatePageInner">

			<div id="titleSection">
				<div id="logoWrap">
					<img id="logo" src="../../public/img/ZBEdge-LO-lightBG.png" alt="mymobility" />
				</div>
			</div>

			<div id="loginSection">
				<div id="loginBox">
					<div id="loginBoxInner">

						<div id="welcomeText" class="bottomSpaceDouble heading">
							{{ $t('redeem_invite_login_button') }}
						</div>

						<div v-if="errorDisplayKey" class="alertBox bottomSpace">{{ $t(errorDisplayKey) }}</div>

						<div class="section">
							<div class="label">
								<label :for="email">{{ $t('email_address') }}</label>
							</div>

							<div>
								<input type="email" class="emailInput editProfileInput" v-model="email" />
							</div>

							<div class="error" v-if="emailError">{{ emailError }}</div>
						</div>

						<div class="bottomSpaceDouble section">
							<div class="label">
								<label :for="selectedCountry">{{ $t('select_country_prelogin') }}</label>
							</div>

							<div>
								<select v-model="selectedCountry" @change="CountrySelected()" data-testid="countrySelector">
									<option v-for="country in countries" v-bind:disabled="country.id === 0" v-bind:key="country.id" v-bind:value="country" v-bind:data-testid="country.abbreviation">{{ country.name }}</option>
								</select>
							</div>

							<div class="error" v-if="countryError">{{ countryError }}</div>
						</div>

						<div class="button-section">
							<button class="button" type="button" @click="SubmitForLogin">{{ $t('redeem_invite_validate_phone_button') }}</button>
						</div>

						<a id="newUserStartHere" href="/find-invites">{{ $t('new_user_start_here') }}</a>

					</div>
				</div>

			</div>

		</div>

	</div>
</template>

<script lang="ts">
  import CwePageHeader from '../components/CwePageHeader.vue';
  import { Country, ICountry } from '../models/Country';
  import { countryModule } from '../store/countryModule';
  import { store } from '../store/store';
  import * as Vue from 'vue';
  import { GetSelectedCountryCookie, GetNoPreloginRedirectCookie, SetSelectedCountryCookie } from '../services/Cookies/PreLoginCookies';
  import WaitForAppDependencies from '../services/AppDependencyService';
  import FixProxyUrl from '../services/FixProxyUrl';
  import GetUnixTimestampString from '../services/GetUnixTimestampString';
  import { i18n } from '@/plugins/i18n'
  import * as EmailValidator from 'email-validator';
  const $t = i18n.global.t;


  interface IErrorKeys {
    [key: string]: string | undefined;
  }

  var ErrorMessages: IErrorKeys = {
    about_url_not_found: 'about_url_not_found',
    countries_not_found: 'countries_not_found',
    localization_strings_not_found: 'localization_strings_not_found',
    prelogin_no_country_selected: 'prelogin_no_country_selected',
    no_matching_user_found: 'no_matching_user_found',
    support_url_not_found: 'support_url_not_found',
    zbedge_app_urls_not_found: 'zbedge_app_urls_not_found',
    no_apps_enabled_for_your_account: 'no_apps_enabled_for_your_account',
	login_migration: 'login_migration', //renamed to login_cwe_redirect in code 
	login_cwe_redirect: 'login_cwe_redirect', 
    optivu_tenant_login_required: 'optivu_tenant_login_required',
    please_complete_registration: 'Please complete registration before continuing.',
    Error_AccountLocked: 'Error_AccountLocked',
    Error_AuthenticationFailure: 'Error_AuthenticationFailure',
    access_denied: 'access_denied',
    Error_CodeValidationExpired:'Error_CodeValidationExpired', 
    Error_DisallowedNetworkAddress: 'Error_DisallowedNetworkAddress',
    Error_InvalidCountry: 'Error_InvalidCountry',
    Error_CodeValidationAttemptsExceeded: 'Error_CodeValidationAttemptsExceeded',
	forbidden: 'insufficient_permissions',
	session_timeout: 'session_timeout'
  };

  function GetLoginPath(selectedCountryCode: string, email:string){
      let path = `/login?countryCode=${selectedCountryCode}&locale=${store.state.browserLocale}&timestamp=${GetUnixTimestampString()}`;          

      if(store.state.appRedirect)
          path += '&app=' + encodeURIComponent(store.state.appRedirect);

	  if (email)
		  path += '&email=' + encodeURIComponent(email);
      
      return path; 
    }
  
  export default Vue.defineComponent({
    name: 'PreLoginPage',
    components: {
      CwePageHeader,
    },
    data() {
      return {
        Promises: [
          this.LoadCountries()
        ],
        errorDisplayKey: '',
        appRedirectKey: '',
        networkErrorOccured: false,
        countries: [] as ICountry[],
        selectedCountry: new Country,
        defaultCountry: new Country,
		email: '',
        emailError: '',
		countryError: ''
      }
    },
    provide() {
      return {

      }
    },
    beforeRouteEnter: async (to, from, next) => {
      let errorKey = to.query.errorKey?.toString() ?? '';
      errorKey = errorKey.trim();

      if (errorKey != null && errorKey.length > 0) {
        next();
        return;
      }

      let noRedirectValue = to.query.noRedirect?.toString() ?? 'false';
      let noRedirect = (noRedirectValue === 'true');

      if (noRedirect) {
        next();
        return;
      }

      const selectedCountryCode = GetSelectedCountryCookie();
      const noPreloginRedirect = GetNoPreloginRedirectCookie()=='true';

      if (!noPreloginRedirect && selectedCountryCode != null && selectedCountryCode.length > 0) {
        window.location.href=FixProxyUrl(GetLoginPath(selectedCountryCode, ''));
        return;
      }

      next();
    },
    created: async function () {
      await WaitForAppDependencies();
      await this.SetCountries();
      var selectedCountryCode = this.GetSelectedCountryCode();
      this.SetSelectedCountryOnPageLoad(selectedCountryCode);
      const noPreloginRedirect = GetNoPreloginRedirectCookie()=='true';

      let errorKey = this.$route.query.errorKey?.toString() ?? '';
      errorKey = errorKey.trim();
      errorKey = this.GetErrorKeyFromTranslatedString(errorKey);

      if (errorKey != null && (ErrorMessages[errorKey] || ErrorMessages[errorKey.toLowerCase()])) {
        let tmpErrorKey = ErrorMessages[errorKey];
        if (!tmpErrorKey)
          tmpErrorKey = ErrorMessages[errorKey.toLowerCase()] ?? errorKey;
        tmpErrorKey = tmpErrorKey.replace('login_migration', 'login_cwe_redirect')
        this.errorDisplayKey = tmpErrorKey;
      }
      else if (store.state.alwaysShowMigrationMessage) {
        this.errorDisplayKey = 'login_cwe_redirect';
      }

      let noRedirectValue = this.$route.query.noRedirect?.toString() ?? 'false';
      let noRedirect = (noRedirectValue === 'true');

      if (!noPreloginRedirect && noRedirect === false && selectedCountryCode != null && selectedCountryCode != '') {
        if (this.errorDisplayKey == '' || (store.state.alwaysShowMigrationMessage && this.errorDisplayKey == 'login_cwe_redirect')) {
          window.location.href = FixProxyUrl(GetLoginPath(selectedCountryCode, this.email));
          return;
        }
      }
    },
    methods: {
      GetErrorKeyFromTranslatedString(errorKey: string) {
        for (let key in ErrorMessages) {
          let tmpErrorKey = ErrorMessages[key];

          if (!tmpErrorKey)
            tmpErrorKey = ErrorMessages[key.toLowerCase()] ?? key;

          tmpErrorKey = tmpErrorKey.replace('login_migration', 'login_cwe_redirect');

          if ($t(tmpErrorKey) == errorKey || $t(tmpErrorKey).toLowerCase() == errorKey.toLowerCase()) {
            return tmpErrorKey;
          }
        }

        return errorKey;
      },
      LoadCountries: async function () {
        
        const loadCountriesResult = await countryModule.loadCountries();

        if (loadCountriesResult!=null && !loadCountriesResult.countriesLoadFailed && loadCountriesResult.countries != null) {
          this.countries = loadCountriesResult.countries;
        }
        else {
          if(loadCountriesResult?.networkError)
            this.networkErrorOccured=true;

          if (!window.location.href.includes("countries_load_failed")) {
            throw Error("countries_load_failed")
          }
        }
      },
      SetCountries: async function () {
        store.state.loadingCount++;

        const results = await Promise.all(this.Promises.map(p => p.catch((e: Error) => e)));
        const errors = results.filter(result => result instanceof Error);

        store.state.loadingCount--;

        if (errors.length > 0) {
          const error = (errors.pop() as Error);
          let errorUrl = '/error?errorKey=' + error.message;
          if(this.networkErrorOccured)
           errorUrl+='&networkError=true';
          window.location.href = FixProxyUrl(errorUrl);
          return;
        }

        const defaultCountry = this.countries.filter(country => country.id == "0");

        if (defaultCountry != null && defaultCountry.length > 0 && defaultCountry[0] != null)
          this.defaultCountry = defaultCountry[0]
      },
      SetSelectedCountryOnPageLoad(selectedCountryCode: string) {
        if (selectedCountryCode == null || selectedCountryCode == '') {
          this.selectedCountry = this.defaultCountry;
          return;
        }

        const currentSelectedCountry = this.countries.filter(country => country.abbreviation == selectedCountryCode)

        if (currentSelectedCountry != null && currentSelectedCountry.length > 0 && currentSelectedCountry[0] != null) {
          this.selectedCountry = currentSelectedCountry[0];
          return currentSelectedCountry[0];
        }
      },
      GetSelectedCountryCode() {
        const selectedCountryCode = GetSelectedCountryCookie();
        return selectedCountryCode;
      },
      IsCountrySelected() {
        return this.selectedCountry != null && this.selectedCountry.id != null && this.selectedCountry.id !== '' &&
          this.defaultCountry != null && this.defaultCountry.id != null && this.defaultCountry.id !== '' &&
          this.selectedCountry.id !== this.defaultCountry.id;
      },
      CountrySelected() {
        SetSelectedCountryCookie(this.selectedCountry);

        if (this.IsCountrySelected() == true)
          if (store.state.alwaysShowMigrationMessage) {
            this.errorDisplayKey = 'login_cwe_redirect';
          }
          else {
            this.errorDisplayKey = '';
          }
      },
      SubmitForLogin() {
        let hasError = false;

		this.emailError = '';
		this.countryError = '';
		if (EmailValidator.validate(this.email) == false) {
		  this.emailError = $t('required_email');
	      hasError = true;
		}

        if (this.IsCountrySelected() == false) {
          //this.errorDisplayKey = ErrorMessages.prelogin_no_country_selected as string;
		  this.countryError = $t(ErrorMessages.prelogin_no_country_selected as string);
	      hasError = true;
        }

		if (!hasError){
          let path = GetLoginPath(this.selectedCountry.abbreviation, this.email);
          window.location.href = FixProxyUrl(path);
        }
      }
      
    },
  })
</script>

<style lang="scss">
	#PreLoginPage {
		background: $light_gray;
		background-image: url('/public/img/zbedge-login-background.png');
		background-size: cover;
		background-position: center;
		min-height: 100% !important;
		display: flex;
		flex: 2;
		flex-direction: column;
		justify-content: flex-start;

		#AzureTemplatePageInner {
			display: flex;
			width: 100%;
			justify-content: space-evenly;
			flex: 2;

			#titleSection {
				display: flex;
				flex-direction: column;
				align-content: center;
				justify-content: center;
				align-items: stretch;
				margin: 0;
				padding: 40px 4% 40px 4%;
				text-align: center;
				width: 50%;
				box-sizing: border-box;

				#logo {
					max-width: 100%;
				}
			}

			#loginSection {
				display: flex;
				flex-direction: column;
				justify-content: center;
				text-align: center;
				width: 50%;
				box-sizing: border-box;
				padding-inline:20px;

				#loginBox {
					padding-block: 40px;
					display: flex;
					flex-direction: column;
					background: #fff;
					width: auto;
					min-width: 550px;
					max-width: 600px;
					padding-inline: 40px;
					border-radius: 40px;
					box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, .2509803922);
					margin-inline: auto;

					#loginBoxInner {
						margin-inline: auto;
						width: 100%;
						box-sizing: border-box;
					}

					#welcomeText {
						font-weight: 700;
						font-size: 36px;
						font-weight: bold;
						margin-bottom: 40px;
						text-align: left;
					}

					#newUserStartHere {
						color: #3b77c4 !important;
						font-weight: bold;
						font-size: .9rem;
						display: block;
						text-decoration: none;
						width: 100%;

						&:hover, &:focus {
							color: #80C0E4 !important;
						}
					}

					label {
						font-weight: bold;
					}

					.button-section {
						text-align: right;

						button {
							min-width: auto;
						}
					}
				}
			}
			// Keep styling in sync with the Login.html / Azure b2c login page styling.
			select, input[type=text], input[type=email] {
				min-width: 180px;
				width: 100%;
				max-width: unset;
				height: 3rem;
			}


			@media all and (max-width:1200px) {
				flex-direction: column;
				justify-content: flex-start;

				#titleSection {
					padding: 40px 2% 20px 2%;
					width: auto;
				}

				#loginSection {
					display: block;
					justify-content: start;
					width: auto;

					#loginBox {
						min-width: unset;
						width: 600px;
						margin-inline: auto;

						.button-section {
							text-align: center;

							button {
								min-width: 66%;
							}
						}
					}
				}
			}


			@media all and (max-width:660px) {

				#titleSection {
					#logo {
						max-width: 60%;
					}
				}

				#loginSection #loginBox {
					width: auto !important;
					margin-inline: 4%;
					padding-inline: 4%;
					border-radius: 16px;

					#loginBoxInner {
						max-width: auto;
						width: 100%;
						margin-inline: 0;

						.button-section {
							button {
								min-width: auto;
								width: 100%;
							}
						}
					}
				}
			}
		}
	}


</style>
