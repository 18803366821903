export function GetSelectedCountryCookie() {
    const name = 'LastSelectedCountryCode';
    return GetCookieByName(name);
}

export function GetNoPreloginRedirectCookie() {
    const name = 'NoPreloginRedirect';
    return GetCookieByName(name);
}

export function GetCookieByName(str) {
    const name = str+'=';
    const decodedCookie = decodeURIComponent(document.cookie);
    const ca = decodedCookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];

        while (c.charAt(0) == ' ') {
            c = c.substring(1);
        }

        if (c.indexOf(name) == 0)
            return c.substring(name.length, c.length);
    }

    return '';
}


export function SetSelectedCountryCookie(selectedCountry: any) {
  if (selectedCountry == null)
    return;

  const d = new Date();
  d.setTime(d.getTime() + 1 * 24 * 60 * 60 * 1000);
  const expires = 'expires=' + d.toUTCString();
  document.cookie = 'LastSelectedCountryCode=' + selectedCountry.abbreviation + ';' + expires + ';path=/';
}